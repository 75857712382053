"use strict";

import BaseController from "Controllers/base.controller";
import _ from "lodash";
import "./appModal.scss";

export interface AppModalEventData {
    modalId: string;
    targetButton?: HTMLButtonElement | HTMLElement;
}

class AppModal extends BaseController {

    private static readonly selectors = {
        modal: '.js-app-modal',
        openTriggers: '.js-app-modal-open',
        closeTriggers: '.js-app-modal-close',
        inner: '.js-app-modal-inner',
        closeButton: '.js-app-modal-close',
        title: '.js-app-modal-title',
        append: '.js-app-modal-append',
        actions: '.js-app-modal-actions'
    }

    private static readonly elements = {
        html: document.querySelector('html') as HTMLElement
    }

    private static openedModals: { id: string, props: Partial<Record<string, any>> }[] = [];

    public init = async () =>
    {

        this.addClickEvent(AppModal.selectors.openTriggers, this.open);
        this.addClickEvent(AppModal.selectors.closeTriggers, this.close);
        this.addClickEvent(AppModal.selectors.modal, this.backdropClose);

        window.addEventListener('load', () => {

            document.querySelectorAll(AppModal.selectors.modal).forEach(modal => modal.classList.add('page-loaded'));

        });

        console.log("App Modal");

    }

    public static manualOpen = async (button: HTMLButtonElement) =>
    {

        const $modal = document.getElementById(button.dataset.modal);

        if (!$modal)
            return;

        await AppModal.show(button, $modal, {...button.dataset}, true);

    }

    private open = async (e: any): Promise<void> =>
    {

        e.preventDefault();

        const $trigger = this.getEventTarget<HTMLElement>(e, AppModal.selectors.openTriggers);

        const $modal = document.getElementById($trigger.dataset.modal);

        if (!$modal)
            return;

        await AppModal.show(e, $modal, {...e.target.dataset});

    }

    private close = async (e: any): Promise<void> =>
    {

        e.preventDefault();

        const $modal = e.target.closest('.js-app-modal');

        if (!$modal)
            return;

        await AppModal.hide(e, $modal);

    }

    private backdropClose = async (e: any): Promise<void> =>
    {

        if (e.target.closest(AppModal.selectors.inner)) {
            return;
        }

        if (!AppModal.openedModals.length) {
            return;
        }

        const firstOpenedModal = document.getElementById(AppModal.openedModals[AppModal.openedModals.length - 1].id);

        if (firstOpenedModal.classList.contains("prevent-backdrop-close"))
        {
            return;
        }

        await AppModal.hide(e, firstOpenedModal);

    }

    private static show = async (e: any, $modal: HTMLElement, props: Partial<Record<string, any>>, isManualOpen: boolean = false): Promise<void> =>
    {

        return new Promise(resolve => {

            const $elements = AppModal.getModalElements($modal);

            $elements.closeButton.style.display = props.display || $elements.closeButton.dataset.initialDisplay;
            $modal.classList.add('show');
            AppModal.elements.html.classList.add('app-modal-opened');

            const modalIndex = _.findIndex(AppModal.openedModals, {
                id: $modal.getAttribute('id')
            });

            if (modalIndex < 0)
                AppModal.openedModals.push({
                    id: $modal.getAttribute('id'),
                    props
                });

            const appModalShowEvent = new CustomEvent<AppModalEventData>('appModalOpened', {
                detail: {
                    modalId: props.modal,
                    targetButton: !isManualOpen ? e.target : e
                }
            });

            document.dispatchEvent(appModalShowEvent);

            resolve();

        });

    }

    private static hide = async (e: any, $modal: HTMLElement): Promise<void> =>
    {

        return new Promise(resolve => {

            const $elements = AppModal.getModalElements($modal);

            AppModal.elements.html.classList.remove('app-modal-opened');
            $modal.classList.remove('show');
            $elements.closeButton.style.display = $elements.closeButton.dataset.initialDisplay;

            const modalIndex = _.findIndex(AppModal.openedModals, {
                id: $modal.getAttribute('id')
            });

            if (modalIndex < 0)
                return;

            const currentModal = AppModal.openedModals[modalIndex];

            AppModal.openedModals.splice(modalIndex, 1);

            const appModalHideEvent = new CustomEvent<AppModalEventData>('appModalClosed', {
                detail: {
                    modalId: currentModal.id,
                    targetButton: e.target
                }
            });

            document.dispatchEvent(appModalHideEvent);

            resolve();

        })

    }

    private static getModalElements = ($modal: HTMLElement): { main: HTMLElement, closeButton: HTMLButtonElement, title: HTMLElement, append: HTMLElement, actions: HTMLElement } =>
    {

        const $closeButton: HTMLButtonElement = $modal.querySelector(AppModal.selectors.closeButton);
        const $title: HTMLElement = $modal.querySelector(AppModal.selectors.title);
        const $append: HTMLElement = $modal.querySelector(AppModal.selectors.append);
        const $actions: HTMLElement = $modal.querySelector(AppModal.selectors.actions);

        return {
            main: $modal,
            closeButton: $closeButton,
            title: $title,
            append: $append,
            actions: $actions
        }

    }

}

export default AppModal;