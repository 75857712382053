"use strict";

// @ts-ignore
//global.Popper = require('popper.js');

import 'bootstrap';
import "toastr";
import './base.scss'

import AppModal from "Components/appModal/appModal";
import AppNav from "Components/appNav/AppNav";

declare global {
    interface Window {
        lang: string;
    }
}

(async function (window) {

    let lang = document.getElementsByTagName('html')[0].getAttribute('lang');
    window.prototype.lang = lang.toLowerCase();

    // (new Loader).init();
    await (new AppNav()).init();
    await (new AppModal()).init();

})(Window);