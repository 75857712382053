"use strict";

import BaseController from "Controllers/base.controller";
import "./AppNav.scss";

class AppNav extends BaseController{

    private readonly selectors = {
        toggleSideNav: '#toggleSide',
        sideNav: '.sidenav',
        sideNavClose: '#closeSide',
        backdropCloseNav: '#closeSideBackdrop'
    };

    public init = (): void => {
        this.addClickEvent(this.selectors.toggleSideNav, this.openSideNav);
        this.addClickEvent(this.selectors.sideNavClose, this.closeSideNav);
        this.addClickEvent(this.selectors.backdropCloseNav, this.closeSideNav);
    };

    private openSideNav = () => {
        const sideNav: HTMLElement = document.querySelector(this.selectors.sideNav);
        sideNav.classList.add('showSideNav');
        const backdrop: HTMLElement = document.querySelector('.backdrop-pannel');
        backdrop.classList.add('backdrop-pannel-show');
    };

    private closeSideNav = () => {
        const sideNav: HTMLElement = document.querySelector(this.selectors.sideNav);
        sideNav.classList.remove('showSideNav');
        const backdrop: HTMLElement = document.querySelector('.backdrop-pannel');
        backdrop.classList.remove('backdrop-pannel-show');
    };

}

export default AppNav;
